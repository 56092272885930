import {Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'

import {formatISO, isPast, isToday, startOfDay} from 'date-fns'
import {filter, find, forEach, includes, isEmpty, isNil, omit, toString} from 'lodash'

import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes} from '../../redux/modals'

import {tasksSchema} from '../../assets/validations'
import {objectKeysToSnakeCase} from '../../utils'

import {Button} from '../Button'
import {ColorPicker} from '../ColorPicker'
import {DatePicker} from '../DatePicker'
import {ErrorsList} from '../ErrorComponents'
import {Input} from '../Input'
import {Modal} from '../Modal'
import {RequiredFieldsText} from '../RequiredFieldsText'
import {Select} from '../Select'
import {Toggle} from '../Toggle'

import './EditTaskModal.scss'
import {useDebouncedState} from '../../utils/hooks'
import {useQueryResourceDetail, useQueryResourceList, useQueryResourceSearch} from '../../queries/rest'
import {CANCELED_STATE} from '../../utils/constants'

const getPersons = (entityProfiles) => {
    let persons = []

    forEach(entityProfiles, (person) => {
        persons.push({
            value: person.id,
            label: `${person.first_name} ${person.last_name}`,
            subscriber_category: 'entity-profiles'
        })
    })

    return persons
}

export const EditTaskModal = ({
    open,
    task,
    isLoading,
    nonFieldErrors,
    fieldErrors,
    selectedEntityID,
    updateTask
}) => {
    const [projectsQuery, setProjectsQuery] = useDebouncedState('', 300)
    const [contractsQuery, setContractsQuery] = useDebouncedState('', 300)
    const [requesterQuery, setRequesterQuery] = useDebouncedState('', 300)
    const [selectedProject, setSelectedProject] = useState(null)

    const {data: defaultProject, isFetching: isLoadingDefaultProject} = useQueryResourceDetail(
        RESOURCES_V2.projects, task.project_id, {enabled: open}
    )
    const {data: projects, isFetching: isLoadingProjects} = useQueryResourceSearch(
        RESOURCES_V2.projects, projectsQuery,
        {entity_id: selectedEntityID, active: true},
        {enabled: open}
    )

    const {data: defaultContract, isFetching: isLoadingDefaultContract} = useQueryResourceDetail(
        RESOURCES.contracts, task.contract_id, {enabled: !!(open && task.contract_id)}
    )
    const {data: contracts, isFetching: isLoadingContracts} = useQueryResourceSearch(
        RESOURCES.contracts,
        contractsQuery,
        {
            entity_id: selectedEntityID,
            project_id: selectedProject?.id || task.project_id,
            state_not: [CANCELED_STATE]
        },
        {enabled: !!(open && selectedProject)}
    )

    const {data: defaultRequester, isFetching: isLoadingDefaultRequester} = useQueryResourceDetail(
        RESOURCES.contactPersons, task.requester_id, {enabled: !!(open && task.requester_id)}
    )
    const {data: contactPersons, isFetching: isLoadingContactPersons} = useQueryResourceSearch(
        RESOURCES.contactPersons,
        requesterQuery,
        {entity_id: selectedEntityID, project_id: selectedProject?.id || task.project_id},
        {enabled: !!(open && selectedProject)}
    )

    const {data: entityProfilesResponse, isFetching: isLoadingEntityProfiles} = useQueryResourceList(
        RESOURCES.entityProfiles,
        {entity_id: selectedEntityID, page_size: 100},
        {enabled: open}
    )

    const entityProfiles = entityProfilesResponse?.data?.results || []
    const notifyPersons = getPersons(entityProfiles)

    useEffect(() => {
        if (defaultProject) {
            setSelectedProject(defaultProject)
        }
    }, [defaultProject])

    const getInitialTaskSubscribers = () => task.task_subscribers.map((sub) =>
        find(notifyPersons, (p) => toString(sub.subscriber_id) === toString(p.value))
    )

    const internalFormatISO = (date, allDay) => {
        let isoDate

        if(allDay) {
            isoDate = formatISO(startOfDay(date))
        } else {
            isoDate = formatISO(date)
        }

        return isoDate
    }

    const isLoadingInitialData = (
        isLoadingDefaultContract || isLoadingDefaultProject || isLoadingDefaultRequester || isLoadingEntityProfiles
    )

    return (
        <Modal open={open && !isEmpty(task)} title='Editează sarcină'>
            {!isLoadingInitialData && !isEmpty(task) && (
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            projectId: defaultProject,
                            contractId: defaultContract,
                            ownersIds: filter(entityProfiles, (profile) =>
                                includes(task.owners_ids, profile.id)
                            ),
                            supervisorsIds: filter(entityProfiles, (profile) =>
                                includes(task.supervisors_ids, profile.id)
                            ),
                            title: task.title,
                            requesterId: defaultRequester,
                            timePlanning: !isNil(task.start),
                            allDay: task.all_day,
                            start: !isNil(task.start) ? new Date(task.start) : task.start,
                            stop: !isNil(task.stop) ? new Date(task.stop) : task.stop,
                            billable: task.billable,
                            completion: task.completion ? true : false,
                            color: task.color,
                            taskSubscribers: getInitialTaskSubscribers(),
                            location: task.location || ''
                        }}
                        validationSchema={tasksSchema}
                        onSubmit={(values) => {
                            let start = null
                            let stop = null

                            if(values.timePlanning) {
                                start = internalFormatISO(values.start, values.allDay)
                                stop = internalFormatISO(values.stop, values.allDay)
                            }

                            const taskData = {
                                ...objectKeysToSnakeCase({
                                    ...omit(values, [
                                        'ownersIds',
                                        'supervisorsIds',
                                        'projectId',
                                        'contractId',
                                        'date',
                                        'taskSubscribers',
                                        'timePlanning'
                                    ])
                                }),
                                entity_id: selectedEntityID,
                                owners_ids: values.ownersIds.map((profile) => profile.id),
                                supervisors_ids: values.supervisorsIds.map((profile) => profile.id),
                                requester_id: values.requesterId?.id || null,
                                project_id: values.projectId?.id || null,
                                contract_id: values.contractId?.id || null,
                                all_day: values.timePlanning ? values.allDay : null,
                                start: start,
                                stop: stop,
                                completion: values.completion ? new Date().toISOString() : null,
                                task_subscribers: values.taskSubscribers.map((sub) => ({
                                    subscriber_id: sub.value,
                                    subscriber_category: sub.subscriber_category
                                }))
                            }

                            updateTask(taskData, task.id)
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            isValid
                        }) => (
                            <Form className='add-task-form-container'>
                                <div className="add-task-form-row">
                                    <Select
                                        label='Proiect*'
                                        value={values.projectId}
                                        options={projects}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(option) => {
                                            setSelectedProject(option)
                                            setFieldValue('projectId', option)

                                            setFieldValue('taskSubscribers', [])
                                            setFieldValue('contractId', null)
                                        }}
                                        onInputChange={(value) => setProjectsQuery(value)}
                                        onBlur={handleBlur('projectId')}
                                        name='projectId'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.projectId}
                                        loading={isLoadingProjects}
                                        fullWidth
                                    />
                                    <Select
                                        label='Contract'
                                        value={values.contractId}
                                        options={contracts}
                                        loading={isLoadingContracts}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => setFieldValue('contractId', e)}
                                        onInputChange={(value) => setContractsQuery(value)}
                                        onBlur={handleBlur('contractId')}
                                        name='contractId'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.contractId}
                                        disabled={isNil(values.projectId)}
                                        fullWidth
                                    />
                                </div>
                                <div className="add-task-form-row">
                                    <Select
                                        label='Deținători*'
                                        value={values.ownersIds}
                                        options={entityProfiles}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                        onChange={(e) => setFieldValue('ownersIds', e)}
                                        onBlur={handleBlur('ownersIds')}
                                        name='ownersIds'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.ownersIds}
                                        fullWidth
                                        isMulti
                                    />
                                    <Select
                                        label='Supraveghetori'
                                        value={values.supervisorsIds}
                                        options={entityProfiles}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                        onChange={(e) => setFieldValue('supervisorsIds', e)}
                                        onBlur={handleBlur('supervisorsIds')}
                                        name='supervisorsIds'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.supervisorsIds}
                                        isClearable
                                        fullWidth
                                        isMulti
                                    />
                                </div>
                                <Input
                                    label='Titlu'
                                    value={values.title}
                                    onChange={handleChange('title')}
                                    onBlur={handleBlur('title')}
                                    name='title'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.title}
                                    fullWidth
                                />
                                <Select
                                    label='Solicitant'
                                    value={values.requesterId}
                                    options={contactPersons}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => `${option.last_name} ${option.first_name}`}
                                    disabled={isNil(values.projectId)}
                                    onChange={(e) => setFieldValue('requesterId', e)}
                                    onBlur={handleBlur('requesterId')}
                                    name='requesterId'
                                    loading={isLoadingContactPersons || isLoadingDefaultRequester}
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    onInputChange={(value) => setRequesterQuery(value)}
                                    touched={touched.requesterId}
                                    isClearable
                                    fullWidth
                                />
                                <Toggle
                                    label='Planificare timp'
                                    checked={values.timePlanning}
                                    onChange={(e) => setFieldValue('timePlanning', e)}
                                    onBlur={handleBlur('timePlanning')}
                                    name='timePlanning'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.timePlanning}
                                />
                                {values.timePlanning && (
                                    <>
                                        <Toggle
                                            label='Toată ziua'
                                            checked={values.allDay}
                                            onChange={(e) => setFieldValue('allDay', e)}
                                            onBlur={handleBlur('allDay')}
                                            name='allDay'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.allDay}
                                        />
                                        <div className={`add-task-form-row ${!values.allDay ? 'asym-split' : ''}`}>
                                            <DatePicker
                                                label='Data start*'
                                                value={values.start}
                                                onChange={(date) => isNil(date) ?
                                                    setFieldValue('start', date) :
                                                    setFieldValue('start', new Date(date))
                                                }
                                                onBlur={handleBlur('start')}
                                                name='start'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.start}
                                                fullWidth
                                            />
                                            {!values.allDay && (
                                                <DatePicker
                                                    label='Ora start'
                                                    value={values.start}
                                                    onChange={(date) => setFieldValue('start', new Date(date))}
                                                    timeFormat='HH:mm'
                                                    timeSelect
                                                    fullWidth
                                                />
                                            )}
                                        </div>
                                        <div className={`add-task-form-row ${!values.allDay ? 'asym-split' : ''}`}>
                                            <DatePicker
                                                label='Data stop'
                                                value={values.stop}
                                                onChange={(date) => isNil(date) ?
                                                    setFieldValue('stop', date) :
                                                    setFieldValue('stop', new Date(date))
                                                }
                                                onBlur={handleBlur('stop')}
                                                name='stop'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.stop}
                                                fullWidth
                                            />
                                            {!values.allDay && (
                                                <DatePicker
                                                    label='Ora stop'
                                                    value={values.stop}
                                                    onChange={(date) => setFieldValue('stop', new Date(date))}
                                                    timeFormat='HH:mm'
                                                    timeSelect
                                                    fullWidth
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                                <div className="add-task-form-row">
                                    <div className="w-full">
                                        <Toggle
                                            label='De facturat'
                                            checked={values.billable}
                                            onChange={(e) => setFieldValue('billable', e)}
                                            onBlur={handleBlur('billable')}
                                            name='billable'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.billable}
                                        />
                                    </div>
                                    <div className="w-full">
                                        {isPast(values.start) && !isToday(values.start) && (
                                            <Toggle
                                                label='Sarcină finalizată'
                                                checked={values.completion}
                                                onChange={(e) => setFieldValue('completion', e)}
                                                onBlur={handleBlur('completion')}
                                                name='completion'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.completion}
                                            />
                                        )}
                                    </div>
                                </div>
                                <Select
                                    label='Colaboratori interni anunțați'
                                    value={values.taskSubscribers}
                                    options={notifyPersons}
                                    loading={isLoadingEntityProfiles}
                                    disabled={isNil(values.projectId)}
                                    onChange={(e) => setFieldValue('taskSubscribers', e)}
                                    onBlur={handleBlur('taskSubscribers')}
                                    name='taskSubscribers'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.taskSubscribers}
                                    fullWidth
                                    isMulti
                                />
                                <div className="add-task-form-row">
                                    <ColorPicker
                                        label='Culoare'
                                        color={values.color}
                                        onChange={(hex) => setFieldValue('color', hex)}
                                        onBlur={handleBlur('color')}
                                        name='color'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.color}
                                        isClearable
                                    />
                                    <Input
                                        label='Locație'
                                        value={values.location}
                                        onChange={handleChange('location')}
                                        onBlur={handleBlur('location')}
                                        name='location'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.location}
                                        fullWidth
                                    />
                                </div>
                                <RequiredFieldsText />
                                <Button
                                    title='Salvează'
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    color='secondary'
                                    type='submit'
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        open: state.modals.type === modalTypes.EDIT_TASK,
        isLoading: state.tasks.isLoading,
        fieldErrors: state.tasks.fieldErrors,
        nonFieldErrors: state.tasks.nonFieldErrors,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateTask: (data, taskID) => dispatch(RESOURCES.tasks.update(data, taskID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskModal)
