import React, {useCallback, useMemo} from 'react'

import {lightFormat, parseISO} from 'date-fns'
import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {durationAsHoursAndMinutes, toApiDateFormat} from '../../../../utils'
import {datatablePageSize} from '../../../../utils/datatables'

import {PageLoader} from '../../../../components/PageLoader'

import './InvoiceActivityReport.scss'
import ResourceDatatable from '../../../../components/ResourceDatatable/ResourceDatatable.tsx'
import {Loader} from '../../../../components/Loader'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {useQuery} from '@tanstack/react-query'

const ReportsHeader = ({timeLogsReports, isFetchingTimeLogsReports}) => {
    const duration = timeLogsReports?.duration ?
        durationAsHoursAndMinutes(timeLogsReports?.duration) : '...'
    const durationAsHours = timeLogsReports?.duration_as_hours || '...'

    const durationBillable = timeLogsReports?.duration_billable ?
        durationAsHoursAndMinutes(timeLogsReports?.duration_billable) : '...'
    const durationBillableAsHours = timeLogsReports?.duration_billable_as_hours || '...'

    return (
        <div className='invoice-activity-report-custom-header-container'>
            <p>
                Pe baza filtrelor facturii, în intervalul de raportare setat, vă putem oferi următoarele
                informații de activitate.
            </p>
            <p>
                Raportul poate fi descărcat de la butonul de descărcarea din dreapta sus.
            </p>
            <hr/>
            {!isFetchingTimeLogsReports ? (
                <div className='invoice-activity-report-custom-header-container-totals'>
                    <p>Timp total: <span>{duration} ({durationAsHours})</span></p>
                    <p>Timp total facturabil: <span>{durationBillable} ({durationBillableAsHours})</span></p>
                </div>
            ) : (
                <Loader/>
            )}
        </div>
    )
}

export const InvoiceActivityReport = ({
    invoice,
    isLoadingInvoice,
    selectedEntityID
}) => {
    const filters = {
        entity_id: selectedEntityID,
        client_id: invoice.client_id,
        project_id: invoice.project_id,
        page_size: datatablePageSize,
        contract_id: invoice.contract_id,
        ...(invoice.reports_start && {
            date__gte: toApiDateFormat(invoice.reports_start), date__lte: toApiDateFormat(invoice.reports_stop)
        })
    }

    const {data: timeLogsReports, isFetching: isFetchingTimeLogsReports} = useQuery({
        queryKey: [RESOURCES.taskTimeLogTotalsReports.name, filters],
        queryFn: async () => {
            const response = await performRequest(RESOURCES.taskTimeLogTotalsReports.list(filters))

            return response.data
        }
    })
    const convertTimeToISODate = useCallback((time, date = new Date()) => {
        const formatedDate = toApiDateFormat(date)

        if (_.isNil(time)) {
            return null
        }

        return parseISO(`${formatedDate}T${time}`)
    }, [])

    const localFormatTime = useCallback((date) => {
        if(_.isNil(date)){
            return '-'
        }

        return lightFormat(new Date(date), 'HH:mm')
    }, [])

    const columns = useMemo(() => {
        return [
            {
                Header: 'Dată',
                accessor: 'date',
                Cell: ({value: date}) => lightFormat(new Date(date), 'dd/MM/yyyy')
            },
            {
                Header: 'Interval orar',
                accessor: '_interval',
                Cell: ({row: {original: {date, start, stop}}}) => {
                    const startDate = convertTimeToISODate(start, date)
                    const stopDate = convertTimeToISODate(stop, date)

                    return `${localFormatTime(startDate)} - ${localFormatTime(stopDate)}`
                }
            },
            {
                Header: 'Avocat',
                accessor: 'owner',
                Cell: ({value: owner}) => owner.full_name || '-'
            },
            {
                Header: 'Sarcină',
                accessor: 'task_title',
                Cell: ({row: {original: {task}}}) => !_.isNil(task) ? task.title : '-'
            },
            {
                Header: 'Descriere activitate',
                accessor: 'description',
                Cell: ({value: description}) => description || '-'
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value: project}) => !_.isNil(project) ? project.name : '-'
            },
            {
                Header: 'Solicitant',
                accessor: 'task_requester',
                Cell: ({row: {original}}) => {
                    const requester = _.get(original, ['task', 'requester'])

                    return !_.isNil(requester) ?
                        `${requester.first_name} ${requester.last_name}` :
                        '-'
                }
            },
            {
                Header: 'Ore lucrate',
                accessor: 'duration_as_hours',
                Cell: ({value: durationAsHours}) => durationAsHours ? durationAsHours : '-'
            },
            {
                Header: 'Clienți',
                accessor: 'clients_names',
                Cell: ({value: clientsNames}) => _.join(clientsNames, ', ')
            }
        ]
    }, [convertTimeToISODate, localFormatTime])

    if(!_.isEmpty(invoice) && !isLoadingInvoice && invoice.reports_start) {
        return (
            <ResourceDatatable
                title='Raport activitate'
                resource={RESOURCES.taskTimeLogs}
                filters={filters}
                columns={columns}
                customHeader={() => (
                    <ReportsHeader
                        timeLogsReports={timeLogsReports} isFetchingTimeLogsReports={isFetchingTimeLogsReports}
                    />
                )}
                emptyText='Nu există raport de activitate pentru această factură'
            />
        )
    }

    if (!invoice.reports_start) {
        return <div>Această factură nu are un raport atașat.</div>
    }

    if(isLoadingInvoice) return <PageLoader />
}

const mapStateToProps = (state) => ({
    invoice: state.invoices.currentInvoice,
    isLoadingInvoice: state.invoices.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps)(InvoiceActivityReport)