import React, {useCallback, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'

import {TrashIcon} from '@heroicons/react/24/outline'

import {differenceInDays, lightFormat} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {setCurrent as setCurrentSubscription} from 'avoapp-react-common/dist/redux/subscriptions'
// @ts-ignore
import {OrderingTypes} from 'avoapp-react-common/dist/constants'
import {useDebouncedState} from 'hooks'
import {useQueryResourceList} from 'queries/rest'
import {connect} from 'react-redux'
import {modalTypes, openModal} from 'redux/modals'

import {Cell} from 'types/local'
import {sourceTypes} from 'utils/types'

import {AddMDManualSubscriptionModal} from '../../../components/AddMDManualSubscriptionModal'
import {AddMDSubscriptionModal} from '../../../components/AddMDSubscriptionModal'

import {Button} from 'components/Button'
import {DeleteSubscriptionModal} from 'components/DeleteSubscriptionModal'
import {TableLink} from 'components/TableLink'
// @ts-ignore
import {Datatable} from '../../../components/Datatable'

type MonitorDosareDatatableProps = {
    selectedEntityID: string
    openModal: (modalType: string) => void
    setCurrentSubscription: (subscription: any) => void
}

export const MonitorDosareDatatable = ({
    openModal,
    selectedEntityID,
    setCurrentSubscription
}: MonitorDosareDatatableProps) => {
    const [_searchQuery, setSearchQuery, debouncedSearchQuery] = useDebouncedState('')
    const [subscriptionsPage, setSubscriptionsPage] = useState<number>(1)

    const {data: subscriptionsResult, isFetching: isFetchingSubscriptions} = useQueryResourceList(
        RESOURCES.subscriptions,
        {
            search: debouncedSearchQuery,
            entity_id: selectedEntityID,
            page: subscriptionsPage,
            ordering: OrderingTypes.INTERVAL_DESC,
            page_size: 15
        }
    )

    const subscriptions = useMemo(() => {
        if (!(subscriptionsResult as any)?.data?.results) {
            return []
        }

        return _.orderBy((subscriptionsResult as any).data.results, ['interval', 'id'], ['asc', 'desc'])
    }, [subscriptionsResult])

    const pages = useMemo(() => {
        return {
            page: (subscriptionsResult as any)?.data?.page,
            number_of_pages: (subscriptionsResult as any)?.data?.number_of_pages,
            previous: (subscriptionsResult as any)?.data?.pages.previous,
            next: (subscriptionsResult as any)?.data?.pages.next
        }
    }, [subscriptionsResult])

    const getHearingReadableTime = useCallback((date) => {
        const difference = differenceInDays(new Date(date), new Date(new Date().toDateString()))

        const formatedDate = lightFormat(new Date(date), 'dd/MM/yyyy')

        if (difference === 0) {
            return `${formatedDate} (Astăzi)`
        }

        if (difference === 1) {
            return `${formatedDate} (în 1 zi)`
        }

        if (difference > 0) {
            return `${formatedDate} (în ${difference} zile)`
        }

        return formatedDate
    }, [])

    const renderTimeToHearing = useCallback(
        (subscription) => {
            const hearingDate = subscription?.next?.hearing?.date ?? subscription?.previous?.hearing?.date ?? null

            if (_.isNull(hearingDate)) {
                return null
            }

            return getHearingReadableTime(hearingDate)
        },
        [getHearingReadableTime]
    )

    const subscriptionsColumns = useMemo(() => {
        return [
            {
                Header: 'Număr dosar',
                accessor: 'litigation_number',
                Cell: ({value, row: {original}}: Cell) => (
                    <TableLink to={`/external-data/monitor-dosare/${original.id}`}>
                        {value} ({sourceTypes[_.toUpper(original.source)]})
                    </TableLink>
                )
            },
            {
                Header: 'Obiect',
                accessor: 'litigation.phases',
                Cell: ({value: phases}: Cell) => {
                    if (!Array.isArray(phases) || phases.length === 0) {
                        return '-'
                    }

                    return _.upperFirst((_.last(phases) as any).object)
                }
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value}: Cell) => <TableLink to={`/projects/${value?.id}`}>{value?.name}</TableLink>
            },
            {
                Header: 'Clienți',
                accessor: 'project.clients',
                Cell: ({value}: Cell) => (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {value.map((client: any, index: number) => (
                            <React.Fragment key={index}>
                                <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                                {index + 1 !== value.length && ', '}
                            </React.Fragment>
                        ))}
                    </div>
                )
            },
            {
                Header: 'Următorul/Ultimul termen',
                accessor: 'next_previous_hearing',
                Cell: ({row: {original}}: Cell) => {
                    console.log('original: ', original)

                    return renderTimeToHearing(original)
                }
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({value: subscriptionID, row: {original: subscription}}: Cell) => (
                    <div className="flex gap-2 items-center">
                        <div className="datatable-row-buttons-container">
                            <Link to={`/external-data/monitor-dosare/${subscriptionID}`}>
                                <Button
                                    title="Vizualizare"
                                    size="small"
                                />
                            </Link>
                            <Button
                                icon={() => <TrashIcon />}
                                onClick={() => {
                                    setCurrentSubscription(subscription)
                                    openModal(modalTypes.DELETE_SUBSCRIPTION)
                                }}
                                size="small"
                                color="red"
                            />
                        </div>
                    </div>
                )
            }
        ]
    }, [openModal, renderTimeToHearing, setCurrentSubscription])

    return (
        <div className="page-external-data-show">
            <Datatable
                title="Listă dosare monitorizate"
                data={subscriptions}
                headerButton={() => (
                    <Button
                        title="Adaugă abonare"
                        onClick={() => openModal(modalTypes.ADD_MONITOR_DOSARE_SUBSCRIPTION)}
                        color="secondary"
                    />
                )}
                columns={subscriptionsColumns}
                loading={isFetchingSubscriptions}
                previousPage={pages.previous}
                currentPage={pages.page}
                nextPage={pages.next}
                totalPages={pages.number_of_pages}
                onChangePage={(page: number) => setSubscriptionsPage(page)}
                searchable
                searchPlaceholder="Caută surse de date"
                onSearch={(event: any) => setSearchQuery(event.target.value)}
            />
            <AddMDSubscriptionModal />
            <AddMDManualSubscriptionModal />
            <DeleteSubscriptionModal />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: string) => dispatch(openModal(modalType)),
    setCurrentSubscription: (subscription: any) => dispatch(setCurrentSubscription(subscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(MonitorDosareDatatable)
